/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import {signOutOfApp,getPhotoUrlOfUser} from './firebase'
import { Helmet } from "react-helmet";
import OnlineStatus from './onlinestatus'
//import { Router, Link } from "@reach/router"
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

function classNames(...classes:any) {
  return classes.filter(Boolean).join(' ')
}

function Nav(props: any) {

  // Declare a new state variable, which we'll call "count"
  const [urlOfProfileIcon, setUrlOfProfile] = useState("");
  // Declare a new state variable, which we'll call "count"
  const [loadedPfpStatus, setLoadedPfpStatus] = useState(false);
  
  useEffect(() => {
    var urlstring: any

    getPhotoUrlOfUser()
      .then((url) => {
        urlstring = url;
        setUrlOfProfile(urlstring)
        if (url !== "/rickroll.jpeg") {
          setLoadedPfpStatus(true)
        }
      })
    
    // grab everything we need
    if (props.mode === "backarrow") {
  
    }
  });

  function openMenu() {
    if (props.mode === "backarrow") {
      //props.sidebar.classList.toggle("-translate-x-full");
      if (props.sidebarstate === true) {
        props.setsidebarstate(false)
      } else {
        props.setsidebarstate(true)
      }
    }
  }

  return (
    <div>
      <nav
        className="border-white border-b-2 text-sm text-left text-gray-100 bg-gray-900 p-2 md-4 h-12 flex items-center md-p-4"
        role="alert"
      >
        <div className="w-full relative flex items-center justify-between h-16">
          {/* focus:outline-none focus:bg-gray-700 */}

          <div className='flex flex-row'>
{
          props.listsidebarstate === false && props.sidebarstate === true && (
            <div className=" text-gray-100 flex justify-between md:hidden">
            <button onClick={() => {
              props.setlistsidebar(true);
            }} className="mobile-menu-button p-1 ">
         <p className='underline text-lg'>Lists</p>
        </button>
        </div>
          )
   }

{
            (props.listsidebarstate === false && props.sidebarstate === false) && (
              <div className=" text-gray-100 flex justify-between md:hidden">
                <button onClick={openMenu} className="mobile-menu-button p-4 ">
              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>


            </div>
  )
   }

   {
     props.onmainpage !== true && (
      <Link to="/">
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
      <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
            </svg>
            
          </Link>
     )
   }
   
</div>
          
      <div className="object-right absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
  {
    props.showconnected && (
      <OnlineStatus
      socketconnected={props.socketconnected}
    />
    )
  }
      <button
      type ="button"
      className="object-right bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
      >
      <span className="sr-only">View notifications</span>
      <BellIcon className="h-6 w-6" aria-hidden="true" />
      </button>

    {/* Profile dropdown */}
      <Menu as="div"className="ml-3 relative object-right">
      <div>
      <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
      <span className="sr-only">Open user menu</span>
    {
      loadedPfpStatus && (
      <img
      className="h-8 w-8 rounded-full"
      src={urlOfProfileIcon}
      alt=""
      />
    )
    }
    {
      !loadedPfpStatus && (
      <div className="h-8 w-8 rounded-full">
      <svg className="animate-spin  text-blue-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>

      </div>
    )
    }
      </Menu.Button>
      </div>
      <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
      >
      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none">
                     
               
      <Menu.Item>
    {({ active }) => (
      <>
      <a
      href='/preferences'
      className={classNames(active?'bg-gray-700': 'bg-gray-800', 'hover:bg-gray-700 block px-4 py-2 text-sm text-gray-50')}
      >
      Preferences
      </a>
    
       </>
    )}
      </Menu.Item>
      <Menu.Item>
    {({ active }) => (
      <>
      <div
      onClick={signOutOfApp}
      className={classNames(active?'bg-gray-700': 'bg-gray-800', 'hover:bg-gray-700 block px-4 py-2 text-sm text-gray-50')}
      >
      Sign out
      </div>
    
       </>
    )}
      </Menu.Item>
    
      </Menu.Items>
      </Transition>
      </Menu>
      </div>
    </div>
   </nav>
    </div>
)
}

export default Nav