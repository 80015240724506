import domains from "./domains";
import React, {lazy, useState, useRef} from "react";
import { io } from "socket.io-client";
import _ from "lodash";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { queryHelpers } from "@testing-library/react";

const isEqual = require("react-fast-compare");
const getAuthFunc = getAuth();

var forEach = require("for-each")
const CampaignMainPage = lazy(() => import('./campaignmainpage'));

export function CampaignSocketWrapperFunc(props:any) {
  const [listOfLists, setListOfLists] = useState([]);
  const [flagtypes, setflagtypes] = useState([]);
  const [flagtypeshasloaded, setflagtypeshasloaded] = useState(false);
  const lastSeendUidsTimeout = useRef(null);
}

export class CampaignSocketWrapperClass extends React.Component<any, any> {
    maintainSocketTimer: any;
    presenceIntervalTimer :any;
    socketpresence: any;
    socketmain: any;
    pollingForListChannelData: any;
    lastrequestlist: any;
    presenceIntervalMade:any;
    lastrequestchannelsoflistamount:any;
    constructor(props: any) {

        super(props);
        this.presenceIntervalMade = React.createRef();
        this.lastrequestlist = React.createRef();
        this.lastrequestchannelsoflistamount = React.createRef();
        this.lastrequestchannelsoflistamount.current = 0;
        this.lastrequestlist.current = null;
        this.presenceIntervalMade.current = false;
        this.state = {
            listOfLists: [],
            flagtypes: {},
            flagtypeshasloaded: false,
            presenceusers: {},
            flagsnumbers: {},
            flagsnumbershasloaded: false,
            lastSeenUidsTime: {},
            activeUidsNumbers: {},
            profilesByUid: {}    ,
            listsLoaded: false,
            socketconnected: false,
            listOfChannelsForEachList: {},
            hasThisListLoadedYet: {},
            currentListData: {},
            currentListView: "",
            hasLoadedPref: false,
            seperatesides: false,
            profilepic: false,
            hasLoadedSocket: false,
            activeOnlySwitch: true,            
            filterFlags: false,
            lastlisthash: '0',
      listintervalid: 'nothing',
      lasthash: '0'
        };
      }

    


      setActiveOnlySwitch = (newstate:any) => {
        console.log('final function, change state to', newstate)
        this.setState((state:any, props:any) => {
          return {activeOnlySwitch: newstate}
        });
      }

      setFilterFlags = (newstate:any) => {
        this.setState((state:any, props:any) => {
          return {filterFlags: newstate}
        });
      }

      

      setCurrentListView = (listid:any) => {
        this.setState({currentListView: listid})
      }

      checkCurrentListView = () => {
        const urlParams = new URLSearchParams(window.location.search);

        var listidparam = urlParams.get("listid");

        if (listidparam === null || listidparam === "all" || listidparam === "") {
          this.setCurrentListView("all")
        } else {
          this.setCurrentListView(listidparam)
        }
      }

      pollingListChannelsData = () => {
        
      const urlParams = new URLSearchParams(window.location.search);

      var listidparam = urlParams.get("listid")

      if (listidparam) {
        if (listidparam != "all") {
          var options:any = {
            listid: listidparam,
            activeonly: this.state.activeOnlySwitch,
            campaignid: this.props.campaignid,
            previousHash: this.state.previousHash
        }


        var typeofinstruction:string = "normal";

        //this is the first request for any list anywhere, so you can just ask for the first twenty
        if (this.lastrequestlist.current) {
          //if the same list is being requested as the same time
          if (this.lastrequestlist.current === listidparam) {
            if (this.lastrequestchannelsoflistamount.current === 1) {
              //second request this time
              typeofinstruction = "aftertwenty";
            } else {
              typeofinstruction = "after2instructions";
            }
          } else {
            //if this is a different list, then you need to reset the amount of times you have requested and request the first twenty
            typeofinstruction = "firsttwenty";
          }
          
        } else {
          typeofinstruction = "firsttwenty";
        }

        this.lastrequestlist.current = listidparam;

        if (typeofinstruction === "firsttwenty") {
          options["filteronlyfirsttwenty"] = true;
          this.lastrequestchannelsoflistamount.current = 1;
          options["bypasshashcheck"] = true;
        }
        if (typeofinstruction === "aftertwenty") {
          options["filterafterfirsttwenty"] = true;
          this.lastrequestchannelsoflistamount.current = 2;
          options["bypasshashcheck"] = true;
        }

        if (typeofinstruction === "after2instructions") {
          this.lastrequestchannelsoflistamount.current = this.lastrequestchannelsoflistamount.current + 1;
        }

        console.log('options type',  typeofinstruction)

       this.socketmain.emit('getListChannels', options)
        }
      }
      }


      
    fetchPrefData = () => {
        
      if (getAuthFunc.currentUser) {
          getAuthFunc.currentUser.getIdToken().then((idToken: any) => {
              var object = {
                  firebasetoken: idToken
              }
              
          fetch(domains.httpapi + "/getuserpref", {
              mode: "cors",
              credentials: "omit",
              headers: {
                "Content-Type": "application/json",
              },
              method: "POST",
              body: JSON.stringify(object),
            })
            .then(async (results) => {
                var resultsInJson = await results.json();
  
                if (this.state.hasLoadedPref === false)  {
                  this.setState({
                      hasLoadedPref: true,
                      seperatesides: resultsInJson.seperatesides,
                      profilepic:  resultsInJson.profilepic,
                      lexend:  resultsInJson.lexend
                  })
                }
  
               
            })
            .catch((getuserpreferror) => {
              console.error(getuserpreferror)
            })
          }
          )
      }
      }
      

     loadList = (listid:any) => {
         var options = {
             listid: listid,
             activeonly: this.state.activeOnlySwitch,
             campaignid: this.props.campaignid
         }
        this.socketmain.emit('getListChannels', options)

         this.correctListInfo()
     }

     refreshflags = () => {
      this.socketmain.emit('refreshflags', {
        "refreshflags": true
      })
    }
     
     shouldComponentUpdate(nextProps:any, nextState:any) {
     if (false) {
      if (isEqual(nextProps, this.props) && isEqual(this.state,nextState) &&
      this.state.activeOnlySwitch === nextState.activeOnlySwitch && 
      this.state.hasLoadedPref === nextState.hasLoadedPref &&
      this.state.activeOnlySwitch === nextState.activeOnlySwitch
    ) {
    //  console.log('WRAPPER cancel')
      return false;
    } else {
     // console.log('WRAPPER update')
      return true;
    }
    } else {
      return true;
    }
    }
     
     sendReads = (array:any) => {
      
      const urlParams = new URLSearchParams(window.location.search);

      var isuploadreadblocked = urlParams.get("blockread") || urlParams.get("blockreads")

      if (isuploadreadblocked) {

      } else {
        
      this.socketmain.emit('uploadreads', {
        reads: array
      })
      }

     }

     correctListInfo = () => {

      const urlParams = new URLSearchParams(window.location.search);

      var listidparam = urlParams.get("listid")

      if (this.state.listOfLists) {

       // console.log(this.state.listOfLists)

        var filtered = this.state.listOfLists.filter((eachItem:any) => 
        
          eachItem.listid === listidparam
        )

      //  console.log('filtered', filtered)

        var objToSet = {}

        if (filtered.length > 0) {
          objToSet = filtered[0];
       //   console.log('success filt')
        }

        this.setState({
          currentListData: objToSet
        })
       }
     }

     clickOnListButton = (listid:any) => {
      this.setCurrentListView(listid)
         this.loadList(listid);
        
     }

      attemptConnectSocket() {
        if (this.socketpresence.connected) {
          getAuthFunc.currentUser.getIdToken().then((idToken: any) => {
            this.socketpresence.io.opts.query = 'token=' + idToken + '&campaignid=' + this.props.campaignid;
            this.socketpresence.connect()
           // let hello = async () => {}
           // hello();
          })
        }

     //   console.log('socket status main page', this.socketmain.connected)
        if (this.socketmain.connected) {
        }  else {
          if (getAuthFunc.currentUser) {
              
            getAuthFunc.currentUser.getIdToken().then((idToken: any) => {
              this.socketmain.io.opts.query = 'token=' + idToken + '&campaignid=' + this.props.campaignid;
              this.socketmain.connect()
             // let hello = async () => {}
             // hello();
            })
          }
        }

        //   console.log('socket status main page', this.socketmain.connected)
        if (this.socketpresence.connected) {
        }  else {
          if (getAuthFunc.currentUser) {
              
            getAuthFunc.currentUser.getIdToken().then((idToken: any) => {
              this.socketpresence.io.opts.query = 'token=' + idToken + '&campaignid=' + this.props.campaignid;
              this.socketpresence.connect()
             // let hello = async () => {}
             // hello();
            })
          }
        }
      }

      emitToSocket = (tag:any, info:any) => {
        this.socketmain.emit(tag, info)
      }

      emitToPresenceSocket = (tag:any, info:any) => {
        this.socketpresence.emit(tag, info)
      }


        componentDidMount = () => {
          this.fetchPrefData()
          this.checkCurrentListView()

            this.socketmain = 
            io(domains.socketapi, {
              'reconnection': true,
              'reconnectionDelay': 500,
              'reconnectionAttempts': 999999,
              autoConnect: false,
            });

            this.socketpresence = 
            io(domains.presenceapi+ "/presencecampaignws/" + this.props.campaignid, {
              'reconnection': true,
              'reconnectionDelay': 500,
              'reconnectionAttempts': 999999,
              autoConnect: false
            });

            this.socketmain.on('sendListOfChannelsForListPartial', (message:any) => {
              //only if new data has been recieved
                if (message.new === true) {
                  var listOfChannelsToSet: any = this.state.listOfChannelsForEachList;
  
                  
                    var newlistofchannelsobj:any = {};

                  if (listOfChannelsToSet[message.listid]) {
   
                    listOfChannelsToSet[message.listid].forEach((eachInstance:any) => {
                      newlistofchannelsobj[eachInstance.twilionumber] = eachInstance;
                    })

                  }

                  //okay now check if each channel id is a newer instance per phone number

                  message.listofchannels.forEach((eachInstance:any) => {

                    if (newlistofchannelsobj[eachInstance.twilionumber]) {
                      //an old copy was found
                      //check if the new one is newer
                      if (newlistofchannelsobj[eachInstance.twilionumber].timestamp < eachInstance.timestamp) {
                        //write it
                        newlistofchannelsobj[eachInstance.twilionumber] = eachInstance;
                      }
                    } else {
                      //not in the existing list, just write it
                      newlistofchannelsobj[eachInstance.twilionumber] = eachInstance;
                    }

                  })

                  //concert obj to array
                  var newlistofchannels = Object.values(newlistofchannelsobj).sort((a:any, b:any) => a.timestamp - b.timestamp);

                  listOfChannelsToSet[message.listid] = newlistofchannels;
    
                  var  hasThisListLoadedYetToSet: any = this.state.hasThisListLoadedYet;
    
                  hasThisListLoadedYetToSet[message.listid] = true;
    
                  this.setState({
                    listOfChannelsForEachList: listOfChannelsToSet,
                    hasThisListLoadedYet:  hasThisListLoadedYetToSet,
                    previousHash: message.hash
                  });
                }
               
              })

            this.socketmain.on('sendListOfChannelsForList', (message:any) => {
            //only if new data has been recieved
              if (message.new === true) {
                var listOfChannelsToSet: any = this.state.listOfChannelsForEachList;

                listOfChannelsToSet[message.listid] = message.listofchannels;
  
                var  hasThisListLoadedYetToSet: any = this.state.hasThisListLoadedYet;
  
                hasThisListLoadedYetToSet[message.listid] = true;
  
              
                this.setState({
                  listOfChannelsForEachList: listOfChannelsToSet,
                  hasThisListLoadedYet:  hasThisListLoadedYetToSet,
                  previousHash: message.hash
                });
              }
             
            })

            this.socketmain.on("listoflists", (message:any) => {
              if (message.new === true) {
                this.setState({
                  listsLoaded: true,
                  listOfLists: message.rows,
                  lastlisthash: message.hash
              })
              this.correctListInfo()
              }
            });

            this.socketmain.on('allflagtypes', (message:any) => {
              this.setState((state:any, props: any) => {
                var objToSetFlags:any = {

                }

                message.flagtypes.forEach((eachRow:any) => {
                  objToSetFlags[eachRow.flagsnowflake] = eachRow;
                });

                return {
                  flagtypes: objToSetFlags,
                  flagtypeshasloaded: true
                }
              })
            });

            this.socketmain.on('allflagsnumbers', (message:any) => {
              this.setState((state:any, props: any) => {
                var objToSetFlagsNum:any = {

                }

                message.flaggednumbers.forEach((eachRow:any) => {
                  objToSetFlagsNum[eachRow.twilionumber] = eachRow;
                });

                return {
                  flagsnumbers: objToSetFlagsNum,
                  flagsnumbershasloaded: true
                }
              })
            });


            /*
            this.socketmain.on("presenceDownloadv1", (message:any) => {
             //   console.log('presence download')
              //  console.log(message)
            
              this.setState((state:any, props:any) => {
                  var lastSeenUidsTimeToUpdate = state.lastSeenUidsTime;
            
                  lastSeenUidsTimeToUpdate[message.new_val.id] = message.new_val
            
                  return {
                    lastSeenUidsTime: lastSeenUidsTimeToUpdate
                  }
                })
            
              this.updateCurrentPresence();

              
              });*/

              this.socketpresence.on("connect", () => {
                console.log('presence connected');

                var interval = 1000;

                //typescript is so broken
                var connectioninfo:any = navigator.connection;
                if ( connectioninfo.effectiveType === "4g" || connectioninfo.downlink > 10) {
                  interval = 500;
                }
               
                if (this.presenceIntervalMade.current === false) {
                  this.presenceIntervalMade.current = true;
                  this.presenceIntervalTimer = setInterval(() => {
                    this.socketpresence.emit('requestPresence', {
                      campaignid: this.props.campaignid
                    })
                  }, interval)
                }
               
              })
              
              this.socketpresence.on("presenceDownloadv2", (data:any) => {
              //  console.log('presencedata', data)
                this.setState((state:any, props:any) => {
                  return {
                    newpresencemsg: data
                  }
                });

                //if missing a user, attempt to fetch the new user
                var listOfPresenceUsersToUpdate:Array<string> =  _.uniq([].concat.apply([], 
                  (Object.values(data)).map((eachUid:any) => {
                    
                    const result:any = Object.keys(eachUid)

                    return result;
                  
                  
                  })
                  ));
               // console.log('list of the uids in the current presence scope', listOfPresenceUsersToUpdate);
             
            var knownUids = _.uniq(Object.keys(this.state.presenceusers));

              //    console.log('known uids', knownUids);

                  if (knownUids) { 

                    var missingInfoOnTheseUsers:Array<string> = listOfPresenceUsersToUpdate.filter((eachUid:any) => {
                      return !(knownUids.includes(eachUid));
        
                    });
        
                 //   console.log('missing info on these users', missingInfoOnTheseUsers);
        
                    if (missingInfoOnTheseUsers.length > 0) {
                      this.socketpresence.emit('getusers', {hi: true});
                    }
                  }

       

              });

             
             
              
              this.socketpresence.on("allusers", (data:any) => {
                this.setState((state:any, props:any) => {
                  return {
                    presenceusers: data.users
                  }
                })
              })

            this.socketmain.on("connect", () => {
                this.socketpresence.emit('getusers', {hi: true});

              

                console.log('socket.id', this.socketmain.id); // x8WIv7-mJelg7on_ALbx
                console.log('connected to the big boi');
                this.setState({
                    socketconnected: this.socketmain.connected
                })
              });

              this.socketmain.on('disconnect', () => {
                this.setState({
                  socketconnected: this.socketmain.connected
              })
              })

              const urlParams = new URLSearchParams(window.location.search);

              var listidparam = urlParams.get("listid")

              if (listidparam) {
                if (listidparam != "all" && listidparam != ""  && listidparam != null 
                 && listidparam != undefined) {
                  this.loadList(listidparam)
                 }
              }
              
              this.attemptConnectSocket();
              var maintainSocketTimer = setInterval(() => {
                this.attemptConnectSocket();
              }, 500)

            

             var idOfInterval = setInterval(() => {

                //poll for lists
                this.socketmain.emit('fetchlistoflists', {
                  lasthash: this.state.lastlisthash
                })
                

                //poll for channels

                this.pollingListChannelsData();
                if (this.state.hasLoadedPref === false) {
                  this.fetchPrefData()
              }
              }, 1000);

              this.setState({
                idOfInterval,
                maintainSocketTimer
              })
        }

        componentWillUnmount(): void {
            if (this.state.idOfInterval) {
              clearInterval(this.state.idOfInterval);
            }

            if (this.presenceIntervalTimer ) {
              clearInterval(this.presenceIntervalTimer )
            }

            if (this.state.maintainSocketTimer) {
              clearInterval(this.state.maintainSocketTimer)
            }
        }

        render () {
            return ( 
                <CampaignMainPage
                campaignid={this.props.match.params.campaignid}
                listOfLists = {this.state.listOfLists}
                listsLoaded = {this.state.listsLoaded}
                socketmain = {this.socketmain}
                socketconnected={this.state.socketconnected}
                lastSeenUidsTime = {this.state.lastSeenUidsTime}
                activeUidsNumbers = {this.state.activeUidsNumbers}
                emitToSocket = {this.emitToSocket}
                emitToPresenceSocket={this.emitToPresenceSocket}
                clickOnListButton={this.clickOnListButton}
                listOfChannelsForEachList = {this.state.listOfChannelsForEachList}
                hasThisListLoadedYet={this.state.hasThisListLoadedYet}
                currentListData={this.state.currentListData}
                currentListView={this.state.currentListView}
                sendReads={this.sendReads}
                presenceusers={this.state.presenceusers}
                hasLoadedPref={this.state.hasLoadedPref}
                seperatesides={this.state.seperatesides}
                profilepic={this.state.profilepic}
                activeOnlySwitch={this.state.activeOnlySwitch}
                filterFlags={this.state.filterFlags}
                setFilterFlags={this.setFilterFlags}
                flagtypes={this.state.flagtypes}
                flagsnumbers={this.state.flagsnumbers}
                refreshflags={this.refreshflags}
                newpresencemsg={this.state.newpresencemsg}
                setOnlyActiveSwitch={(newstate:any) => {
                  console.log('parentfunctiongot switch change')
                  this.setActiveOnlySwitch(newstate)
                }}
                    {...this.props} />
            )
        }
}
