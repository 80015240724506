import domains from "./domains";

import * as dotenv from 'dotenv' // see https://github.com/motdotla/dotenv#how-do-i-use-dotenv-with-import


import React, { useEffect, useState, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { auth,signInWithGoogle } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Nav from './Nav'
import { app } from './firebase'
import { getAuth } from "firebase/auth";
/* This example requires Tailwind CSS v2.0+ */
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import CampaignButton from './CampaignButton'
import { Helmet } from 'react-helmet'
dotenv.config()

//import "./Login.css";
function Dashboard() {
  const [campaigns, setCampaigns] = useState(null);
  //const [thereAreNoCampaigns, setNoCampaignsState] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();

  const loadCampaigns = () => {
    if (user) {
      user.getIdToken().then(function(idToken:any) {
        // Send token to your backend via HTTPS
        // ...
        var object = {
          firebaseToken: idToken
        }
    
        fetch(domains.httpapi + '/mycampaigns', {
          mode: "cors",
          credentials: "omit",
          headers: {
            'Content-Type': 'application/json'
          },
          method: "POST",
          body:  JSON.stringify(object)
        })
          .then(async (data) => {
            var dataInJson = await data.json()

            setCampaigns(dataInJson);
          
          })
          .catch((fetcherror) => {console.error(fetcherror)})
      }).catch(function(error:any) {
        // Handle error
      });
    }
  }

  useEffect(() => {
    const auth = getAuth();
      const user = auth.currentUser;
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (!user) history.replace("/");

    loadCampaigns();

    setInterval(() => {
      loadCampaigns();
    },1000)
     
  }, [user, loading]);
  return (
    <div className='flex flex-col h-screen bg-gray-900 h-screen h-full w-full'>
      <Helmet
      title="Dashboard | Killa &amp; Kirby Texter"
        />
      <Nav />
      

      {! campaigns && (
          <p className='text-white text-center pt-20 pb-5 object-center'>Loading...<br></br>Our well-fed corgis are fetching your campaigns!</p>
        )
        
      }
      

      
     
      
      <div className="md:container md:mx-auto overflow-y-auto">
        {campaigns && (
          <div className='pt-10 md:pt-20 pb-5 grid grid-cols-1 md:grid-cols-3 space-y-2 md:space-y-0 md:space-x-5'>
        
            {campaigns.campaignlist.map((eachcampaign: any, index: React.Key) => (
              <div key={index}>
                <Link to={`/campaign/${eachcampaign.campaignid}`}>
                <CampaignButton
                  name={eachcampaign.nameOfCampaign}
                    iconurl={eachcampaign.iconurl}
                  bannerurl={eachcampaign.bannerurl}
                  campaignid={eachcampaign.campaignid}
                />
                </Link> 
              </div>
            ))}

            {
              campaigns.campaignlist.length === 0 && (
                <div className='border-dashed border-4 rounded-md flex' style={{
                  aspectRatio: "16/9"
                }}>
                <p className='text-white align-middle m-auto'>You aren't in any campaigns!<br></br>Ask your campaign admin to invite you!<br></br>Contact kylerschin@gmail.com to make a new campaign.</p>
                </div>
              )
            }

          </div>)}
</div>
      </div>
  );
}
export default Dashboard;
