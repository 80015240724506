import domains from "./domains";

function Showenvs() {

return (
<div>
<p>HTTP_API {domains.httpapi}</p>
<p>SOCKET_API {domains.socketapi}</p>
<p>QUEUE_API {domains.queueapi}</p>
<p>PRESENCE_API {domains.presenceapi}</p>
<p>exportapi {domains.exportapi}</p>
</div>
)

}

export default Showenvs;
