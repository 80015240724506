import React, { Suspense, lazy, useEffect } from 'react';
//import logo from './logo.svg';
import './App.css';
import { MantineProvider } from '@mantine/core';
//import { BrowserRouter as Route, Switch } from "react-router-dom";
import Login from './Login'
import Dashboard from './dashboard'
//import NewCampaign from './newcampaign'
//import StandAloneText from './standalonetext'
import Showenvs from './envtest'
import Nav from './Nav';
//import EditCampaign from './editcampaign';
//import CampaignMainPage from './campaignmainpage'
import { warninconsole } from './warnusersaboutxss'
//import history from './history';
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom'
import {UploadFile} from './uploadfile'
import SimpleReactLightbox from 'simple-react-lightbox'

import { NotificationsProvider } from '@mantine/notifications';
import {CampaignSocketWrapperClass} from './CampaignSocketWrapperClass'

//import { datadogRum } from '@datadog/browser-rum';

//import LogRocket from 'logrocket';
//LogRocket.init('qsma3o/mejia-for-controller');

const EditCampaign = lazy(() => import('./editcampaign'));
const NewCampaign = lazy(() => import('./newcampaign'));
const CampaignMainPage = lazy(() => import('./campaignmainpage'));
const CampaignSettings  = lazy(() => import('./campaignsettings'));

const UserPreferences  = lazy(() => import('./MyUserSettings'));

/*
    
datadogRum.init({
    applicationId: '26b60353-6ee4-4547-a9d7-b864c9198de5',
    clientToken: 'pub0f18a7c866629cb16482c943753fb17a',
    site: 'datadoghq.com',
    service:'mejia-text',
    env:'prod',
    // Specify a version number to identify the deployed version of your application in Datadog 
     version: '1.0.1',
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input'
});
    
datadogRum.startSessionReplayRecording();*/

function App() {

  const history = useHistory();


  useEffect(() => {
    warninconsole()
    setTimeout((function() {
      warninconsole()
  }
    ), 1e3)
    setTimeout((function() {
      warninconsole()
  }
    ), 1e4)
    setTimeout((function() {
      warninconsole()
  }
  ), 1e5)
  }, [])

  return (
    <MantineProvider theme={{ colorScheme: 'dark' }}>
      <NotificationsProvider position="bottom-right" zIndex={2077}>
     

 
    <SimpleReactLightbox>
    <div className="App h-full">
      
      <Router>
        <Suspense fallback={
          <div className='bg-gray-900 h-full w-full'>
          <div className="md:container md:mx-auto">
    
              </div>
    </div>
      }>
      <Switch>
          <Route exact path="/" component={Login}
            />
             <Route exact path="/campaign/:campaignid" render={(props) =>
            <CampaignSocketWrapperClass
            campaignid={props.match.params.campaignid}
                {...props} />} />
          <Route exact path='/dashboard' component={Dashboard}/>
          <Route exact path='/createcampaign' component={NewCampaign} />
          <Route exact path='/testenvs' component={Showenvs}/>
        
    
            
              
        <Route path="/campaign/:campaignid/settings/:page" render={(props) =>
            <CampaignSettings
            campaignid={props.match.params.campaignid}
            page={props.match.params.page}
                {...props} />} />

<Route path="/campaign/:campaignid/settings/" render={(props) =>
            <CampaignSettings
            campaignid={props.match.params.campaignid}
            page="nothing"
                {...props} />} />
           
<Route path='/preferences'
render={(props) => <UserPreferences />}
/>

    </Switch>
    </Suspense>
    
  </Router>
    </div></SimpleReactLightbox>
    </NotificationsProvider>
    </MantineProvider>

  );
}

export default App;
