import React, { useEffect, useState } from "react";
import { Socket } from "socket.io-client";
//import { Link, useHistory } from "react-router-dom";
//import { auth,signInWithGoogle } from "./firebase";
//import { useAuthState } from "react-firebase-hooks/auth";
//import { app } from './firebase'
//import { getAuth } from "firebase/auth";
/* This example requires Tailwind CSS v2.0+ */
//import { Disclosure, Menu, Transition } from '@headlessui/react'
//import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
//import CampaignButton from './CampaignButton'
import { warninconsole } from "./warnusersaboutxss";

async function fetchWithTimeout(resource: any, options: any) {
  const { timeout = 8000 } = options;
  
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal  
  });
  clearTimeout(id);
  return response;
}

//import "./Login.css";
function OnlineStatus(props:any) {
  const [connectedStatus, setConnectedStatus] = useState("loading");
//  const [connectedStatusDiv, setConnectedStatusDiv] = useState(null);

useEffect(() => {
 if (true) {
  if (props.socketconnected) {
    setConnectedStatus('online');
  } else {
    setConnectedStatus('offline');
  }
 } else {
  setConnectedStatus('loading');
 }
}, [props.socketconnected])

  useEffect(() => {
    warninconsole();
  }, []);
  
  return (
    <div className='pr-2'>{

      connectedStatus === "online" && (
        <div 
      className="flex place-items-center  bg-green-500 bg-opacity-30 p-1 rounded-full"
      >
         <span
           style={{
            height: 10,
            width: 10,
            marginRight: 3,
            marginLeft: 3
          }}
          className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
        <div className='bg-green-300 flex-1 pulseOnline  align-middle rounded-full'
        style={{
          height: 10,
          width: 10,
          marginRight: 3,
          marginLeft: 3
        }}></div>
       
        <p className='flex-1  align-baseline pr-1 hidden md:block' style={{
          marginTop: 1
          
      }}>Connected</p></div>
      )
    }
      
      {
        connectedStatus === "offline" && (
          <div 
        className="flex place-items-center   bg-red-700 bg-opacity-40 p-1 rounded-full"
        >
           <span
           style={{
            height: 10,
            width: 10,
            marginRight: 3,
            marginLeft: 3
          }}
          className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
          <div className='bg-red-500 flex-1 align-middle rounded-full' style={{
            height: 10,
            width: 10,
            marginRight: 3,
            marginLeft: 3
          }}></div><p className='flex-1  align-baseline pr-1  hidden md:block' style={{
            marginTop: 1
            
        }}>Disconnected</p></div>
        )
      }

      {
        connectedStatus === "loading"
        && (
          <div 
          className="flex place-items-center  bg-yellow-600 bg-opacity-40 p-1 rounded-full"
        >
           <span
           style={{
            height: 10,
            width: 10,
            marginRight: 3,
            marginLeft: 3
          }}
          className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
          <div className='bg-yellow-500 pulseOnline  flex-1 align-middle rounded-full' style={{
              height: 10,
              width: 10,
              marginRight: 3,
              marginLeft: 3
            }}></div><p className='flex-1  align-baseline pr-1  hidden md:block' style={{
              marginTop: 1
              
          }}>Connecting</p></div>
 )      }
    
    </div>
  )
  
}
export default OnlineStatus;