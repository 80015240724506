import  { useEffect, useState } from "react";
import {  useHistory } from "react-router-dom";
import { auth,signInWithGoogle } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Helmet } from "react-helmet";
//import "./Login.css";
function Login() {
 // const [email, setEmail] = useState("");
  //const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) history.replace("/dashboard");
  }, [user, loading]);
  return (
    <div className="flex items-center min-h-screen bg-gray-900">
      <Helmet
      title="Killa and Kirby Campaign Texter"/>
      <div className="container mx-auto">
    <div className="max-w-md mx-auto my-5">
          <div className="text-center">
            <img className='ml-auto mr-auto rounded' src='/picofdogs.jpg'></img>
        <h1 className="my-3 text-3xl font-semibold text-gray-200">Killa Kirby Texter</h1>
            <p className="text-gray-300">Sign in / Sign up</p>
      </div>
          <div className="m-5">
          <button onClick={signInWithGoogle} className="w-full bg-blue-900 hover:bg-blue-800 text-gray-50 font-bold py-2 px-4 rounded">Sign in With Google</button>
      </div>
    </div>
  </div>
</div>
  );
}
export default Login;