/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from 'react'
//import { Disclosure, Menu, Transition } from '@headlessui/react'
//import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
//import {signOutOfApp,getPhotoUrlOfUser} from './firebase'
//import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";

function classNames(...classes:any) {
  return classes.filter(Boolean).join(' ')
}


function CampaignButton(props:any) {

  useEffect(() => {
  });

  return (
    <div className=''>
      <Link to={`/campaign/${props.campaignid}`}>
      <div className='bg-gray-700 rounded-md'>
       <div
        style={{  
          backgroundImage: "url(" + props.bannerurl + ")",
          backgroundPosition: 'center',
          backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          aspectRatio: '21/9'
          }}
          className='w-full'
        ></div>
      <h2 className='text-white font-bold text-base inline-block py-5'>{props.name}</h2>
    </div>
         </Link>
      
    </div>
)
}

export default CampaignButton