export function warninconsole() {
  if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {

  } else {
    if (true) {
      console.log("%c" + "Hold Up!", "color: #5865f2; -webkit-text-stroke: 2px black; font-size: 72px; font-weight: bold;");
    console.log("%c" + "If someone told you to copy/paste something here you have an 11/10 chance you're being scammed.", "font-size: 16px;");
    console.log("%c" + "Pasting anything in here could give attackers access to your Account, Texts, and Campaign. Don't do it!", "font-size: 18px; font-weight: bold; color: red;");
    console.log("%c" + "Unless you understand exactly what you are doing, close this window and stay safe.", "font-size: 16px;")
  
    console.log("%c" + "WARNING!!!", "font-size: 16px; background: yellow; color:red;")
    console.log("%c" + "Using this console may allow attackers to impersonate you and steal your information using an attack called Self-XSS. Do not enter or paste code that you do not understand.", "font-size: 16px;")
    }
  }
 
}